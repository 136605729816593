import {
  Badge,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuList,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { FiBell, FiBellOff, FiClock, FiUser } from "react-icons/fi";
import socketIOClient from "socket.io-client";
import { useSnackbar, closeSnackbar } from "notistack";

const url = process.env.REACT_APP_URL_BACK;

const Notificaciones = () => {
  const [notificaciones, setNotificaciones] = React.useState([]);
  const [unread, setUnread] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openSnack, setOpenSnack] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const audio = new Audio("/assets/sound/notification.mp3");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  async function markAsRead(id) {
    const token = JSON.parse(localStorage.getItem("userData"));
    fetch(url+"/api/v1/notificaciones/markAsRead", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.user.token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((msg) => {
        console.log(msg);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const playSound = async () => {
    audio.play();
  };

  React.useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
    });
    let notifArray = [];
    socket.emit("notificaciones", userData.user);
    socket.on("updateNotificaciones", (data) => {
      setNotificaciones(data);
      notifArray = data;
    });
    socket.on("notificacionActualizacionCama", (data) => {
      console.log(new Date(data.fecha).toLocaleString());
      
      let objNotificacion = {
        descripcion: data.message,
        fecha: data.fecha,
        icon: "/assets/img/logo.png",
        dir: "ltr",
        rol: ""
      }
      
      setNotificaciones([objNotificacion, ...notifArray]);
    });
    
    socket.on("newNotificacion", (data) => {
      setNotificaciones([data, ...notifArray]);
      const notification = new Notification("Puerta de Hierro Tracker", {
        body: data.descripcion,
        timestamp: data.fecha,
        icon: "/assets/img/logo.png",
        dir: "ltr",
      });

      enqueueSnackbar(data.descripcion, {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        style: {
          marginBottom: "10px",
          fontSize: "20px",
        },
        preventDuplicate: true,
        persist: true,
        action: (key) => (
          <>
            <Button
              sx={{ m: 0.5 }}
              variant="contained"
              ///paciente/${data.paciente}
              href={`/${data.rol === 'Hotelería' ? 'limpieza' : 'paciente/' + data.paciente}`}
              //href={`/paciente/${data.id}`}	
            >
              Ir al paciente
            </Button>
            <Button
              sx={{ m: 0.5 }}
              variant="contained"
              color="error"
              onClick={() => closeSnackbar(key)}
            >
              Cerrar
            </Button>
          </>
        ),
      });

      playSound();
      notification.onclick = function (event) {
        event.preventDefault(); // prevent the browser from focusing the Notification's tab
        window.location.href = `/paciente/${data.paciente}`;
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);

  React.useEffect(() => {
    const unreadArr = notificaciones.filter(
      (notificacion) => !notificacion.read
    );
    setUnread(unreadArr.length);
  }, [notificaciones]);

  return (
    <>
      <Tooltip title="Notificaciones">
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Badge badgeContent={unread} color="secondary">
            <FiBell style={{ color: "white" }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          borderRadius: 2,
          "& ::-webkit-scrollbar": {
            width: "0.4rem",
            border: "none",
          },
          "& ::-webkit-scrollbar-track": {
            "WebkitBoxShadow": "inset 0 0 6px rgba(0,0,0,0.3)",
            border: "none",
          },
          "& ::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
            borderRadius: 5,
            border: "none",
          },
        }}
      >
        <MenuList sx={{ height: "315px", width: "550px" }}>
          {notificaciones.map((notificacion) => (
            <Grid
              onClick={async () => {
                if (!notificacion.read) {
                  await markAsRead(notificacion._id);
                }
                window.location.href = `/paciente/${notificacion.paciente}`;
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                },
                p: 1.5,
                backgroundColor: !notificacion.read && "#e69739",
                borderRadius: 2,
                mb: 1,
                mx: 0.5,
              }}
              key={notificacion?._id}
            >
              <Grid
                container
                justifyContent={"start"}
                alignItems="center"
                gap={1}
              >
                <FiClock />
                <Typography sx={{ fontWeight: "bold" }} variant="caption">
                  {new Date(notificacion.fecha).toLocaleString()}
                </Typography>
                {(notificacion.rol !== "") && (<FiUser />)}
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  {" " + notificacion.rol}
                </Typography>
              </Grid>
              <Typography variant="body2" color="textSecondary" component="p">
                {notificacion.descripcion}
              </Typography>
              <Divider />
            </Grid>
          ))}
          {notificaciones.length === 0 && (
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              direction="column"
              sx={{ mt: "120px" }}
            >
              <Grid item>
                <FiBellOff style={{ color: "63666A", fontSize: "35px" }} />
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary" component="p">
                  No hay notificaciones
                </Typography>
              </Grid>
            </Grid>
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export default Notificaciones;
