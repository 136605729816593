import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PacientesB from "./subPages/pacientes/PacientesB";
import ColeccionPacientes from "./subPages/pacientes/ColeccionPacientes";
import PacientesDividido from "./subPages/pacientes/PacientesDividido";
import PacientesDivididoAnalisisCuenta from "./subPages/pacientes/PacientesDivididoAnalisisCuenta";
import PacientesEnCurso from "./subPages/pacientes/PacientesEnCurso";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 5 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PacientesGeneral() {
  const [value, setValue] = React.useState(0);
  const userData = JSON.parse(localStorage.getItem("userData"))?.user || {};

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function calculatePacientes() {
    if (userData.rol.includes("Seguros")) {
      return <PacientesDividido />;
    } else if (userData.rol.includes("Análisis de Cuenta")) {
      return <PacientesDivididoAnalisisCuenta />;
    } else {
      return <PacientesB />;
    }
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab label="Mis pacientes" {...a11yProps(0)} />
          <Tab label="Pacientes en curso" {...a11yProps(1)} />
          <Tab label="Colección de pacientes" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {calculatePacientes()}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PacientesEnCurso />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ColeccionPacientes />
      </TabPanel>
    </Box>
  );
}
