import React, { useState, forwardRef } from "react";
import {
  Autocomplete,
  Chip,
  Grid,
  IconButton,
  Slide,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { BiX } from "react-icons/bi";
import LabelDialogForm from "../LabelDialogForm";
import ResponseError from "../ResponseError";
import Regex from "../../constants/regex";

const url = process.env.REACT_APP_URL_BACK;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.secondary.contrastText,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <BiX />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AgregarPacienteDialog = ({ open, setOpen, setRefresh }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [values, setValues] = useState({
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    aseguradora: null,
    medicos: [],
    cama: null,
    noCuenta: "",
    diagnostico: "",
  });

  const [medicos, setMedicos] = useState([]);
  const [aseguradoras, setAseguradoras] = useState([]);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [camasObj, setCamas] = useState([]);
  

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setValues({
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      aseguradora: null,
      medicos: [],
      cama: null,
      noCuenta: "",
      diagnostico: "",
    });
    setErrors({});
    setError(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (fetching) return;
    setFetching(true);
    //validate values
    const validationErrors = validateFields(values);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length !== 0) return setFetching(false);
    const medicosAux = [...values.medicos];
    values.medicos = values.medicos.map((medico) => medico._id);
    setError(null);
    // trim diagnostico
    values.diagnostico = values.diagnostico.trim();
    //send request
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/pacientes/alta", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
      body: JSON.stringify({
        ...values,
        aseguradora: values.aseguradora ? values.aseguradora._id : undefined,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // reset values
        if (data.error) {
          values.medicos = medicosAux;
          setError(data.error);
          setFetching(false);
        } else {
          setValues({
            nombre: "",
            apellidoPaterno: "",
            apellidoMaterno: "",
            aseguradora: null,
            medicos: [],
            cama: null,
            noCuenta: "",
            diagnostico: "",
          });
          setRefresh(true);
          setOpen(false);
          setFetching(false);
          setError(null);
          setErrors({});
        }
      })
      .catch((_error) => {
        console.log("error", _error);
        setFetching(false);
        setError(_error);
      });
  };

  React.useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+`/api/v1/pacientes/form`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const aseguradorasAux = data["aseguradoras"].sort((a, b) =>
          a.acronimo > b.acronimo ? 1 : -1
        );
        setMedicos(data["medicos"] || []);
        setAseguradoras(aseguradorasAux || []);
      });

    fetch(`${url}/api/v1/habitaciones/listar`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCamas(data)
      })
      .catch((e) => {
        console.log(e);
        setCamas([]);
      });
      
  }, []);

  return (
    <Grid>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullScreen={fullScreen}
        TransitionComponent={Transition}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Nuevo paciente
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            item
            container
            justifyContent={"space-between"}
            xs={12}
            sx={{ flexGrow: 1 }}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Nombre"} />
              <TextField
                autoComplete="off"
                value={values.nombre}
                error={errors.nombre ? true : false}
                helperText={errors.nombre}
                name="nombre"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Apellido Paterno"} />
              <TextField
                autoComplete="off"
                value={values.apellidoPaterno}
                error={errors.apellidoPaterno ? true : false}
                helperText={errors.apellidoPaterno}
                name="apellidoPaterno"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Apellido Materno"} />
              <TextField
                autoComplete="off"
                value={values.apellidoMaterno}
                error={errors.apellidoMaterno ? true : false}
                helperText={errors.apellidoMaterno}
                name="apellidoMaterno"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"No. Cuenta"} />
              <TextField
                autoComplete="off"
                value={values.noCuenta}
                error={errors.noCuenta ? true : false}
                helperText={errors.noCuenta}
                name="noCuenta"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <LabelDialogForm name={"Aseguradora"} />

              <Autocomplete
                value={values.aseguradora}
                disablePortal
                fullWidth
                id="combo-box-demo"
                options={aseguradoras}
                onChange={(_event, newValue) => {
                  setValues({ ...values, aseguradora: newValue });
                }}
                getOptionLabel={(option) => {
                  return `${option.acronimo}`;
                }}
                renderInput={(params) => (
                  <TextField
                    error={errors.aseguradora ? true : false}
                    helperText={errors.aseguradora}
                    fullWidth
                    placeholder="Sin Aseguradora"
                    {...params}
                  />
                )}
              />
            </Grid>

            <Grid container item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Médico"} />
              <Autocomplete
                multiple
                fullWidth
                id="tags-outlined"
                value={values.medicos}
                onChange={(_event, newValue) => {
                  if (values.medicos.length >= 1) return;
                  setValues({
                    ...values,
                    medicos: [
                      ...values.medicos,
                      ...newValue.filter(
                        (option) => values.medicos.indexOf(option) === -1
                      ),
                    ],
                  });
                }}
                options={medicos}
                getOptionLabel={(option) => {
                  return `${option.nombre} ${option.apellidoPaterno} ${option.apellidoMaterno}`;
                }}
                isOptionEqualToValue={(option, value) => {
                  return option._id === value._id;
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    error={errors.medicos ? true : false}
                    {...params}
                    placeholder={
                      values.medicos.length === 0 ? "Selecciona un médico" : ""
                    }
                  />
                )}
                renderTags={(valueTags) =>
                  valueTags.map((value) => (
                    <Chip
                      key={value._id}
                      label={`${value.nombre} ${value.apellidoPaterno} ${value.apellidoMaterno}`}
                      onDelete={() => {
                        setValues({
                          ...values,
                          medicos: values.medicos.filter(
                            (option) => option._id !== value._id
                          ),
                        });
                      }}
                    />
                  ))
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Habitación"} />
              <Autocomplete
                value={values.cama}
                disablePortal
                fullWidth
                id="combo-box-demo"
                options={camasObj.map( (e) => e.numero)}
                onChange={(_event, newValue) => {
                  setValues({
                    ...values,
                    cama: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    error={errors.cama ? true : false}
                    helperText={errors.cama}
                    fullWidth
                    {...params}
                  />
                )}
              />
              {( values.cama !== null) &&(
                <Typography variant="caption">
                  Ubicación: {camasObj.filter((e) => e.numero == values.cama)[0].area}{" "}
                  {camasObj.filter((e) => e.numero == values.cama)[0].piso}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Procedimiento"} />
              <TextField
                autoComplete="off"
                value={values?.diagnostico}
                name="diagnostico"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ResponseError error={error} />
          <Button onClick={handleClose} variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={fetching}
            variant="contained"
            color="secondary"
          >
            Guardar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Grid>
  );
};

function validateFields(values) {
  let errors = {};
  Object.keys(values).forEach((key) => {
    if (key === "diagnostico") return;
    if (!values[key]) {
      if (key === "aseguradora") return;

      errors[key] = "Campo requerido";
    } else if (typeof values[key] === "string" && !values[key].trim()) {
      errors[key] = "Campo requerido";
    }
  });

  // check if nombre is all letters
  if (values.nombre && !Regex.nombres_personas.test(values.nombre)) {
    errors.nombre = "El nombre solo puede contener letras";
  }

  if (
    values.apellidoPaterno &&
    !Regex.nombres_personas.test(values.apellidoPaterno)
  ) {
    errors.apellidoPaterno = "El apellido paterno solo puede contener letras";
  }
  // check if apellidoMaterno is all letters
  if (
    values.apellidoMaterno &&
    !Regex.nombres_personas.test(values.apellidoMaterno)
  ) {
    errors.apellidoMaterno = "El apellido materno solo puede contener letras";
  }

  if (values.telefono && isNaN(values.telefono)) {
    errors.telefono = "El telefono debe ser un numero";
  }

  if (values.medicos && values.medicos.length === 0) {
    errors.medicos = "Debe seleccionar al menos un medico";
  }
  return errors;
}

export default AgregarPacienteDialog;
