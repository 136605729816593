import { CircularProgress, Grid } from "@mui/material";
import React from "react";

import FiltrosContainer from "../../../components/Filtros/FiltrosContainer";
import HabitacionFiltro from "../../../components/Filtros/HabitacionFiltro";
import ResponsableFiltro from "../../../components/Filtros/ResponsableFiltro";
import ListaHabitaciones from "../../../components/ListaHabitaciones/ListaHabitaciones";

const url = process.env.REACT_APP_URL_BACK;

const HistorialHabitaciones = () => {
  const [open, setOpen] = React.useState(false);
  const [habitaciones, setHabitaciones] = React.useState(null);
  const [habitacionesFilter, setHabitacionesFilter] = React.useState(null);

  const fetchData = () => {
    // fetch data
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/limpieza/historialHabitaciones", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // convert idXXXXXX property to id
        data.forEach((row) => {
          Object.keys(row).forEach((key) => {
            if (key.includes("_id")) {
              row["id"] = row[key];
            }
          });
        });
        setHabitaciones(data);
        setHabitacionesFilter(data);
      })
      .catch((e) => {
        console.log(e);
        setHabitaciones(null);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid container spacing={4}>
      {/* filters */}

      {habitaciones && (
        <FiltrosContainer
          data={habitaciones}
          dataFilter={habitacionesFilter}
          setDataFilter={setHabitacionesFilter}
          filtros={[ResponsableFiltro]}
          FiltroPrincipal={HabitacionFiltro}
          fullWidth
        />
      )}

      {/* list */}
      <Grid item xs={12}>
        {habitacionesFilter && (
          <ListaHabitaciones habitaciones={habitacionesFilter} />
        )}
      </Grid>
      {/* show skeleton */}
      {!habitaciones && (
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default HistorialHabitaciones;
