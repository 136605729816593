import {
    Button,
    ButtonGroup,
    Collapse,
    Divider,
    Grid,
    ListItem,
    Typography,
  } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FiEdit } from "react-icons/fi";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { RiDoorOpenLine, RiDoorLine } from "react-icons/ri";
import EditarHabitacionDialog from "../EditarDialogs/EditarHabitacionDialog";

const FichaCama = ({ data, setRefresh }) => { 

    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    const calculateEstatus = (estatus) => {
        let estado = "";
        let icon = <></>;
        let style = {
            width: "3rem",
            height: "3rem",
        };       

        if (!estatus?.estado) {
            estado = "Inactivo";
            style.color = "#BABABA";
            icon = <RiDoorLine style={style} />;
        } else {
            estado = "Activo";
            style.color = "#43B02A";
            icon = <RiDoorOpenLine style={style} />;
        }
        return { estado, icon };
    };

    return (
        <Box sx={{ width: "100%" }}>
          <ListItem
            sx={{
              "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.main + "80",
              },
              borderRadius: "10px",
            }}
          >
            <Grid container direction={"row"}>
              <Grid
                item
                sx={{
                  mt: "auto",
                  mb: "auto",
                }}
                md={1}
                xs={2}
              >
                <Box>{calculateEstatus(data).icon}</Box>
    
                <Typography
                  component="p"
                  variant="body2"
                  color="text.primary"
                  sx={{
                    fontStyle: "italic",
                    display: {
                      xs: "none",
                      md: "flex",
                      textAlign: "center",
                    },
                  }}
                >
                  {calculateEstatus(data).estado}
                </Typography>
              </Grid>
              {/* clickable box */}
              <Grid
                item
                container
                xs={10}
                md={11}
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={handleClick}
              >
                <Grid item sx={{ pl: 3 }} md={10} xs={10}>
                  <Typography component="p" variant="p" color="text.primary">
                    {`${data?.numero}`}
                  </Typography>
    
                  <Typography component="p" variant="body2" color="text.primary">
                    {data?.area == "" && (
                        `Piso: ${data?.piso}`
                    )}
                    {data?.area != "" && (
                        `Piso: ${data?.piso} Área: ${data?.area}`
                    )}
                  </Typography>
                </Grid>
    
                <Grid
                  item
                  md={1}
                  sx={{ ml: "auto" }}
                  xs={1}
                  container
                  justifyContent={"center"}
                  alignContent={"center"}
                >
                  {open ? <IoIosArrowDropup /> : <IoIosArrowDropdown />}
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ maxWidth: "100%" }}
          >
            <Grid container>
              <ButtonGroup
                variant="outlined"
                color="primary"
                aria-label="contained primary button group"
                sx={{ ml: 2, mr: 2, mb: 2, mt: 2, width: "100%" }}
              >
                <Button
                  startIcon={<FiEdit />}
                  sx={{ width: "100%", maxWidth: "100%" }}
                  onClick={() => setOpenDialog(true)}
                >
                  Editar
                </Button>
              </ButtonGroup>
            </Grid>
          </Collapse>
          <Divider />
          {openDialog && (
            <EditarHabitacionDialog
            open={openDialog}
            setOpen={setOpenDialog}
            id={data?.id}
            setRefresh={setRefresh}
            />
      )}
        </Box>
      );


};

export default FichaCama;