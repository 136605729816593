import "./App.css";
import Login from "./pages/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import { esES as coreEsES } from "@mui/material/locale";
import { esES } from "@mui/x-data-grid";

import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import PageNotFound from "./pages/PageNotFound";
import PacienteGraficas from "./pages/PacienteGraficas";
import ResetPassword from "./pages/ResetPassword";
import NavbarB from "./components/NavbarB";
import PacientesGeneral from "./pages/PacientesGeneral";
import DetallePacienteB from "./pages/DetallePacienteB";
import Configuracion from "./pages/Configuracion";
import Limpieza from "./pages/Limpieza";
import { SnackbarProvider } from "notistack";
import PacientesActividad from "./pages/subPages/pacientes/PacientesActividad";
import S3Pacientes from "./pages/S3Pacientes";

const defaultTheme = createTheme(
  {
    typography: {
      fontFamily: "Open Sans",
      h1: {
        fontFamily: "Century Gothic",
      },
      h2: {
        fontFamily: "Century Gothic",
        fontSize: "3rem",
      },
      h3: {
        fontFamily: "Century Gothic",
        fontSize: "2rem",
      },
      h4: {
        fontFamily: "Century Gothic",
        fontWeight: "bold",
      },
      h5: {
        fontFamily: "Century Gothic",
      },
      h6: {
        fontFamily: "Century Gothic",
        fontWeight: "100",
      },
      p: {
        fontFamily: "Open Sans",
      },
      span: {
        fontFamily: "Open Sans",
      },
    },
    palette: {
      background: {
        default: "#F2F2F2",
        paper: "#F2F2F2",
        main: "#F2F2F2",
      },
      primary: {
        main: "#3754a8",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#009fe3",
        contrastText: "#FFFFFF",
      },
      fondo: {
        main: "#D4D4D4",
        contrast: "#63666A",
      },
      sinIniciar: {
        main: "#E88D21",
        contrastText: "#FFFFFF",
        shade: "#7F4F0F",
      },
      enProceso: {
        main: "#0072CE",
        contrastText: "#FFFFFF",
        shade: "#003967",
      },
      terminado: {
        main: "#43B02A",
        contrastText: "#FFFFFF",
        shade: "#215815",
      },
      rechazado: {
        main: "#F44336",
        contrastText: "#FFFFFF",
        shade: "#491410",
      },
    },
  },
  coreEsES,
  esES
);
function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline></CssBaseline>
      <SnackbarProvider maxSnack={4}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Login></Login>} />
            <Route
              exact
              path="/resetPassword/:id/:token"
              element={<ResetPassword></ResetPassword>}
            />
            <Route
              exact
              path="/dashboard"
              element={
                <ProtectedRoute allowedUsersList={["General"]}>
                  <NavbarB index={0}>
                    <Dashboard></Dashboard>
                  </NavbarB>
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/catalogoPaciente"
              element={
                <ProtectedRoute allowedUsersList={["General"]}>
                  <NavbarB index={1}>
                    <PacientesGeneral></PacientesGeneral>
                  </NavbarB>
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/catalogoPaciente/:id"
              element={
                <ProtectedRoute allowedUsersList={["General"]}>
                  <NavbarB index={1}>
                    <PacientesActividad></PacientesActividad>
                  </NavbarB>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/paciente/:id"
              element={
                <ProtectedRoute allowedUsersList={["General"]}>
                  <NavbarB index={1}>
                    <DetallePacienteB></DetallePacienteB>
                  </NavbarB>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/configuracion"
              element={
                <ProtectedRoute
                  allowedUsersList={["Administrador", "Limpieza", "Hotelería"]}
                >
                  <NavbarB index={3}>
                    <Configuracion />
                  </NavbarB>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/limpieza"
              element={
                <ProtectedRoute allowedUsersList={["General"]}>
                  <NavbarB index={2}>
                    <Limpieza></Limpieza>
                  </NavbarB>
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/PacienteGraficas"
              element={
                <ProtectedRoute allowedUsersList={["General"]}>
                  <NavbarB index={4}>
                    <PacienteGraficas></PacienteGraficas>
                  </NavbarB>
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/67f4a8fe7ccdaeb92bd9fce74082b982"
              element={
                <>
                  <S3Pacientes />
                </>
              }
            />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
