import React from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  styled,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { BiX } from "react-icons/bi";
import LabelDialogForm from "../LabelDialogForm";
import ResponseError from "../ResponseError";
import Regex from "../../constants/regex";
import rolArray from "../../constants/rolArray";
import { useTheme } from "@emotion/react";
import pisosArray from "../../constants/pisosArray";

const url = process.env.REACT_APP_URL_BACK;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.secondary.contrastText,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <BiX />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const initialValues = {
  nombre: "",
  apellidoPaterno: "",
  apellidoMaterno: "",
  username: "",
  password: "",
  telefono: "",
  roles: [],
  aseguradora: null,
  clave: "",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AgregarUsuarioDialog = ({ open, setOpen, setRefresh }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [values, setValues] = React.useState(initialValues);
  const [aseguradoras, setAseguradoras] = React.useState([]);

  const [errors, setErrors] = React.useState({});
  const [error, setError] = React.useState(null);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
  };
  const fetchGuardarUsuario = () => {
    //validate values
    const validationErrors = validateFields(values);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length !== 0) return;
    if (!values.roles.includes("Seguros")) {
      delete values.aseguradora;
    } else {
      values.aseguradora = values?.aseguradora?._id;
    }
    setError(null);
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+`/api/v1/usuarios/alta`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.errors) {
          setOpen(false);
          setError(null);
          setRefresh(true);
        } else {
          setError(data.errors.username.message.replace("username", "correo"));
        }
      })
      .catch((_error) => {
        console.log("error", _error);
        setError(_error);
      });
  };

  React.useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+`/api/v1/pacientes/form`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAseguradoras(data["aseguradoras"] || []);
      });
  }, []);

  React.useEffect(() => {
    setValues(initialValues);
  }, [open]);

  return (
    <Grid>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullScreen={fullScreen}
        TransitionComponent={Transition}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Nuevo usuario
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            item
            container
            justifyContent={"space-between"}
            xs={12}
            sx={{ flexGrow: 1 }}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Nombre"} />
              <TextField
                autoComplete="off"
                value={values.nombre}
                error={errors.nombre ? true : false}
                helperText={errors.nombre}
                name="nombre"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Apellido Paterno"} />
              <TextField
                autoComplete="off"
                value={values.apellidoPaterno}
                error={errors.apellidoPaterno ? true : false}
                helperText={errors.apellidoPaterno}
                name="apellidoPaterno"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Apellido Materno"} />
              <TextField
                autoComplete="off"
                value={values.apellidoMaterno}
                error={errors.apellidoMaterno ? true : false}
                helperText={errors.apellidoMaterno}
                name="apellidoMaterno"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Nombre de Usuario"} />
              <TextField
                autoComplete="off"
                inputProps={{ maxLength: 50 }}
                value={values.username}
                error={errors.username ? true : false}
                helperText={errors.username}
                name="username"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Contraseña"} />
              <TextField
                autoComplete="new-password"
                value={values.password}
                error={errors.password ? true : false}
                helperText={errors.password}
                name="password"
                variant="outlined"
                fullWidth
                type="password"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Teléfono"} />
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{"+52"}</InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
                autoComplete="off"
                value={values.telefono}
                error={errors.telefono ? true : false}
                helperText={errors.telefono}
                variant="outlined"
                name="telefono"
                fullWidth
                onChange={handleChange}
                type="text"
              />
            </Grid>

            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Rol"} />
              <Select
                value={values.roles}
                onChange={handleChange}
                error={errors.roles ? true : false}
                helperText={errors.roles}
                name="roles"
                multiple
                sx={{
                  mt: 2,
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
                placeholder="Seleccione uno o más roles"
                input={<OutlinedInput id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                    }}
                  >
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value="" disabled>
                  No hay selección
                </MenuItem>
                {rolArray.map((option, _index) => {
                  return (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={values.roles.indexOf(option) > -1} />
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>            
            {(values.roles.includes("Médico") || values.roles.includes("Médico de enlace") || values.roles.includes("Superdoctor") )&& (
              <Grid item xs={12} sx={{ mb: 1 }}>
                <LabelDialogForm name={"Clave Médico"} />
                <TextField
                  autoComplete="off"
                  inputProps={{ maxLength: 50 }}
                  value={values.clave}
                  error={errors.clave ? true : false}
                  helperText={errors.clave}
                  name="clave"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            )}
            {values.roles.includes("Aseguradora") && (
              <Grid item xs={12} sx={{ mb: 1 }}>
                <LabelDialogForm name={"Aseguradora"} />
                <Autocomplete
                  value={values.aseguradora}
                  disablePortal
                  fullWidth
                  id="combo-box-demo"
                  options={aseguradoras}
                  onChange={(_event, newValue) => {
                    setValues({ ...values, aseguradora: newValue });
                  }}
                  getOptionLabel={(option) => {
                    return `${option.acronimo}`;
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={errors.aseguradora ? true : false}
                      helperText={errors.aseguradora}
                      fullWidth
                      placeholder="Seleccione una aseguradora"
                      {...params}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ResponseError error={error} />
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            onClick={fetchGuardarUsuario}
            variant="contained"
            color="secondary"
          >
            Guardar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Grid>
  );
};

const validateEmpty = (values) => {
  let errors = {};
  Object.keys(values).forEach((key) => {
    if (!values[key]) {
      if (key === "aseguradora" && !values.roles.includes("Aseguradora")) return;
      if (key === "clave" && (!values.roles.includes("Médico") || !values.roles.includes("Médico de enlace") || !values.roles.includes("Superdoctor"))) return;
      errors[key] = "Campo requerido";
    } else if (typeof values[key] === "string" && !values[key].trim()) {
      errors[key] = "Campo requerido";
    }
  });
  return errors;
};

function validateFields(values) {
  let errors = validateEmpty(values);

  // check if nombre is all letters
  if (values.nombre && !Regex.nombres_personas.test(values.nombre)) {
    errors.nombre = "El nombre solo puede contener letras";
  }
  if (
    values.apellidoPaterno &&
    !Regex.nombres_personas.test(values.apellidoPaterno)
  ) {
    errors.apellidoPaterno = "El apellido paterno solo puede contener letras";
  }
  // check if nombre is all letters
  if (
    values.apellidoMaterno &&
    !Regex.nombres_personas.test(values.apellidoMaterno)
  ) {
    errors.apellidoMaterno = "El apellido materno solo puede contener letras";
  }
  if (values.telefono && !Regex.telefono.test(values.telefono)) {
    errors.telefono = "El telefono debe contener 10 digitos";
  }

  if (values.roles.includes("Aseguradora") && values.aseguradora === "") {
    errors.aseguradora = "La aseguradora es requerida";
  }

  if ((values.roles.includes("Médico") || values.roles.includes("Médico de enlace") || values.roles.includes("Superdoctor")) && values.clave === "") {
    errors.clave = "La clave del médico es requerida";
  }

  if (values.roles.length < 1) {
    errors.roles = "Seleccione un rol";
  }

  // check if apellidoPaterno is all letters
  if (values.password && !Regex.password.test(values.password)) {
    errors.password =
      "Formato de contraseña incorrecto, debe contener al menos una letra mayuscula, un numero y ser de al menos 8 caracteres";
  }

  return errors;
}

export default AgregarUsuarioDialog;
