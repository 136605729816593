import { Button, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import EstatusFiltro from "../../../components/Filtros/EstatusFiltro";
import FiltrosContainer from "../../../components/Filtros/FiltrosContainer";
import ListaUsuarios from "../../../components/ListaUsuarios/ListaUsuarios";
import FichaCama from "../../../components/ListarCamas/FichaCama";
import AgregarHabitacionDialog from "../../../components/AgregarDialogs/AgregarHabitacionDialog";
import CamasFiltro from "../../../components/Filtros/CamaFiltro"
import PisoFiltro from "../../../components/Filtros/PisoFiltro";
import AreaFiltro from "../../../components/Filtros/AreaFiltro";

const url = process.env.REACT_APP_URL_BACK;

const Habitaciones = () => {
  const [open, setOpen] = useState(false);
  const [habitaciones, setHabitaciones] = useState(null);
  const [habitacionesFilter, setHabitacionesFilter] = useState(null);
  const [habitacionesAux, setHabitacionesAux] = useState(null);
  const [nombre, setNombre] = useState("");
  const [refresh, setRefresh] = useState(false);
  
  useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = () => {
    // fetch data
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/habitaciones/listar", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach((row) => {
          Object.keys(row).forEach((key) => {
            if (key.includes("_id")) {
              row["id"] = row[key];
            }
          });
        });
        setHabitaciones(data);
        setHabitacionesFilter(data);
        setHabitacionesAux(data);
      })
      .catch((e) => {
        console.log(e);
        setHabitaciones(null);
        setHabitacionesFilter(null);
        setHabitacionesAux(null);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (habitaciones) {
      // filter by name
      if (nombre) {
        setHabitaciones(
          habitaciones.filter((h) =>
            `${h.piso}`.toLowerCase().includes(nombre.toLowerCase())
          )
        );
      } else {
        setHabitaciones(habitacionesAux);
      }
    }
  }, [nombre]);

  return (
    <Grid container spacing={4}>
      {/* filters */}

      {habitaciones && (
        <FiltrosContainer
          data={habitaciones}
          dataFilter={habitacionesFilter}
          setDataFilter={setHabitacionesFilter}
          filtros={[PisoFiltro, AreaFiltro, EstatusFiltro]}
          FiltroPrincipal={CamasFiltro}
        />
      )}

      <Grid item md={3} xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpen(true)}
          fullWidth
          startIcon={<FiPlus />}
        >
          Agregar nuevo
        </Button>
      </Grid>

      {/* list */}
      <Grid item xs={12}>
        {habitacionesFilter && (
          <ListaUsuarios
            data={habitacionesFilter}
            ListComponent={FichaCama}
            setRefresh={setRefresh}
          />
        )}
      </Grid>
      {/* show skeleton */}
      {!habitaciones && (
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}
      <AgregarHabitacionDialog
        setOpen={setOpen}
        open={open}
        setRefresh={setRefresh}
      />
    </Grid>
  );
};

export default Habitaciones;
