import { TextField } from "@mui/material";
import React from "react";

const CamaFiltro = ({ values, setValues }) => {
  const [numero, setNumero] = React.useState("");
  React.useEffect(() => {
    setValues({
      ...values,
      numero: {
        value: numero,
        filter: (row) =>
          row.numero.toLowerCase().includes(numero.toLowerCase()),
      },
    });
  }, [numero]);
  React.useEffect(() => {
    if (values.reset) {
        setNumero("");
    }
  }, [values.reset]);

  return (
    <TextField
      id="outlined-basic"
      label="Habitacion"
      variant="standard"
      fullWidth
      name="cama"
      value={numero}
      onChange={(e) => setNumero(e.target.value)}
    />
  );
};

export default CamaFiltro;