import React from "react";
import { Grid, IconButton, Slide, styled, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { BiX } from "react-icons/bi";
import LabelDialogForm from "../LabelDialogForm";
import ResponseError from "../ResponseError";
import Regex from "../../constants/regex";
import { useTheme } from "@emotion/react";

const url = process.env.REACT_APP_URL_BACK;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.secondary.contrastText,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <BiX />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AgregarHabitacionDialog = ({ open, setOpen, setRefresh }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [values, setValues] = React.useState({
    numero: "",
    piso: "",
    area: "",
  });

  const [errors, setErrors] = React.useState({});
  const [error, setError] = React.useState(null);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
    setValues({
      numero: "",
      piso: "",
      area: "",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //validate values
    const validationErrors = validateFields(values);
    setErrors(validationErrors);    
    if (Object.keys(validationErrors).length !== 0) return;
    setError(null);
    //send request
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/habitaciones/alta", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
      body: JSON.stringify({
        ...values,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        
        if (data.error) {
          setError(data.error);
        } else {
          setValues({
            numero: "",
            piso: "",
            area: "",
          });
          setRefresh(true);
          setOpen(false);
        }
      })
      .catch((errorCatch) => {
        console.log("error", errorCatch);
        setError(errorCatch);
        setOpen(true);
      });
  };

  return (
    <Grid>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        TransitionComponent={Transition}
        fullScreen={fullScreen}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Nueva Habitación
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            item
            container
            justifyContent={"space-between"}
            xs={12}
            sx={{ flexGrow: 1 }}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Número"} />
              <TextField
                autoComplete="off"
                value={values.numero}
                error={errors.numero ? true : false}
                helperText={errors.numero}
                name="numero"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Piso"} />
              <TextField
                autoComplete="off"
                value={values.piso}
                error={errors.piso ? true : false}
                helperText={errors.piso}
                name="piso"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Área"} />
              <TextField
                autoComplete="off"
                value={values.area}
                error={errors.area ? true : false}
                helperText={errors.area}
                name="area"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ResponseError error={error} />
          <Button onClick={handleClose} variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="secondary">
            Guardar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Grid>
  );
};

function validateFields(values) {
  let errors = {};

  Object.keys(values).forEach((key) => {
    if (!values[key]) {
        if (key !== "area") errors[key] = "Campo requerido";
    } else if (typeof values[key] === "string" && !values[key].trim()) {
      errors[key] = "Campo requerido";
    }
  });

  // check if nombre is all letters
  if (values.area && !Regex.nombres_personas.test(values.area)) {
    errors.area = "El área solo puede contener letras";
  }

  return errors;
}
export default AgregarHabitacionDialog;