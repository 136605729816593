import React, { useState, forwardRef } from "react";
import {
  Autocomplete,
  Backdrop,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { FiX } from "react-icons/fi";
import Regex from "../../constants/regex";
import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";
import LabelDialogForm from "../LabelDialogForm";
import ResponseError from "../ResponseError";

const url = process.env.REACT_APP_URL_BACK;

const EditarPacienteDialog = ({ open, setOpen, setRefresh, id }) => {
  const [values, setValues] = useState({
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    aseguradora: null,
    medicos: [],
    cama: "",
    estado: false,
    diagnostico: "",
  });
  const [medicos, setMedicos] = useState([]);
  const [medicosCopy, setMedicosCopy] = useState([]);
  const [aseguradoras, setAseguradoras] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [camasObj, setCamas] = useState([]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setValues({
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      aseguradora: null,
      medicos: [],
      cama: "",
      estado: false,
      noCuenta: "",
      diagnostico: "",
    });
    setFetching(true);
    setOpen(false);
  };

  const handleSubmit = (event) => {
    setFetching(true);
    event.preventDefault();
    //validate values
    const validationErrors = validateFields(values);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length !== 0) return setFetching(false);
    setError(null);
    // trim diagnostico
    if (values.diagnostico) values.diagnostico = values.diagnostico.trim();

    //send request
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/pacientes/actualizar", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
      body: JSON.stringify({
        _id: id,
        ...values,
        aseguradora: values.aseguradora ? values.aseguradora._id : undefined,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // reset values
        if (data.error) {
          setError(data.error);
          setFetching(false);
        } else {
          setValues({
            nombre: "",
            apellidoPaterno: "",
            apellidoMaterno: "",
            aseguradora: null,
            medicos: [],
            cama: "",
            estado: false,
            noCuenta: "",
            diagnostico: "",
          });

          setRefresh(true);
          setOpen(false);
          setFetching(false);
        }
      })
      .catch((_error) => {
        console.log("error", _error);
        setError(_error);
        setFetching(false);
      });
  };

  React.useEffect(() => {
    if (id && open) {
      setValues({
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        aseguradora: null,
        medicos: [],
        cama: "",
        estado: false,
        noCuenta: "",
        diagnostico: "",
      });
      setFetching(true);
      const userData = JSON.parse(localStorage.getItem("userData"));
      fetch(url+`/api/v1/pacientes/form`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (userData?.user?.token || ""),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setMedicos(data["medicos"] || []);
          const aseguradorasAux = data["aseguradoras"].sort((a, b) =>
            a.acronimo > b.acronimo ? 1 : -1
          );
          setAseguradoras(aseguradorasAux || []);
          setMedicosCopy(data["medicos"] || []);

          return fetch(url+`/api/v1/pacientes/${id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + (userData?.user?.token || ""),
            },
          });
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setValues({
            nombre: data["nombre"] || "",
            apellidoPaterno: data["apellidoPaterno"] || "",
            apellidoMaterno: data["apellidoMaterno"] || "",
            aseguradora: data["aseguradora"] || null,
            medicos: data["medicos"] || [],
            cama: data["cama"] || "",
            estado: data["estado"] || false,
            noCuenta: data["noCuenta"] || "",
            diagnostico: data["diagnostico"] || "",
          });
          setFetching(false);
        })
        .catch((_error) => {
          console.log("error", _error);
          setError(_error);
          setFetching(false);
          setOpen(false);
        });
        fetch(`${url}/api/v1/habitaciones/listar`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (userData?.user?.token || ""),
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setCamas(data)
          })
          .catch((e) => {
            console.log(e);
            setCamas([]);
          });

    }
  }, [id, open]);

  /*   React.useEffect(() => {
    if (medicos && values.medicos) {
      // compare two arrays and remove duplicates
      const newMedicos = medicosCopy.filter(
        (medico) => !values.medicos.some((m) => m._id === medico._id)
      );
      setMedicos(newMedicos);
    }
  }, [values.medicos, medicos]); */

  return (
    <Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={fetching && open}
      >
        <CircularProgress />
      </Backdrop>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Editar paciente
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            item
            container
            justifyContent={"space-between"}
            xs={12}
            sx={{ flexGrow: 1 }}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Nombre"} />
              <TextField
                autoComplete="off"
                value={values.nombre}
                error={errors.nombre ? true : false}
                helperText={errors.nombre}
                name="nombre"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Apellido Paterno"} />
              <TextField
                autoComplete="off"
                value={values.apellidoPaterno}
                error={errors.apellidoPaterno ? true : false}
                helperText={errors.apellidoPaterno}
                name="apellidoPaterno"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Apellido Materno"} />
              <TextField
                autoComplete="off"
                value={values.apellidoMaterno}
                error={errors.apellidoMaterno ? true : false}
                helperText={errors.apellidoMaterno}
                name="apellidoMaterno"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"No. Cuenta"} />
              <TextField
                autoComplete="off"
                value={values.noCuenta}
                error={errors.noCuenta ? true : false}
                helperText={errors.noCuenta}
                name="noCuenta"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <LabelDialogForm name={"Aseguradora"} />

              <Autocomplete
                value={values.aseguradora}
                disablePortal
                fullWidth
                id="combo-box-demo"
                options={aseguradoras}
                onChange={(_event, newValue) => {
                  setValues({ ...values, aseguradora: newValue });
                }}
                getOptionLabel={(option) => {
                  return `${option.acronimo}`;
                }}
                renderInput={(params) => (
                  <TextField
                    error={errors.aseguradora ? true : false}
                    helperText={errors.aseguradora}
                    fullWidth
                    placeholder="Sin Aseguradora"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setValues({ ...values, aseguradora: null });
                          }}
                        >
                          <FiX />
                        </IconButton>
                      ),
                    }}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid container item xs={12} sx={{ mb: 1 }}>
              <Autocomplete
                multiple
                fullWidth
                id="tags-outlined"
                loading={fetching}
                value={values.medicos}
                // isOptionEqualToValue={(option, value) => {
                //   console.log(option);
                //   console.log(value);
                //   return medicosCopy.includes(option);
                // }}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onChange={(_event, newValue) => {
                  if (values.medicos.length >= 1) return;
                  setValues({
                    ...values,
                    medicos: [
                      ...values.medicos,
                      ...newValue.filter(
                        (option) => values.medicos.indexOf(option) === -1
                      ),
                    ],
                  });
                }}
                options={medicos}
                getOptionLabel={(option) => {
                  return `${option.nombre} ${option.apellidoPaterno} ${option.apellidoMaterno}`;
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    error={errors.medicos ? true : false}
                    {...params}
                    label="Médico"
                    placeholder={
                      values.medicos.length === 0 ? "Selecciona un médico" : ""
                    }
                  />
                )}
                renderTags={(valueTags) =>
                  valueTags.map((value) => (
                    <Chip
                      key={value._id}
                      label={`${value.nombre} ${value.apellidoPaterno} ${value.apellidoMaterno}`}
                      onDelete={() => {
                        setValues({
                          ...values,
                          medicos: values.medicos.filter(
                            (option) => option._id !== value._id
                          ),
                        });
                      }}
                    />
                  ))
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Cama"} />
              <Autocomplete
                value={values.cama}
                disablePortal
                fullWidth
                id="combo-box-demo"
                options={camasObj.map( (e) => e.numero)}
                onChange={(_event, newValue) => {
                  setValues({
                    ...values,
                    cama: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    error={errors.cama ? true : false}
                    helperText={errors.cama}
                    fullWidth
                    {...params}
                  />
                )}
              />
              {( camasObj.filter((e) => e.numero == values.cama).length != 0) && (
                <Typography variant="caption">
                  Ubicación: {camasObj.filter((e) => e.numero == values.cama)[0].area}{" "}
                  {camasObj.filter((e) => e.numero == values.cama)[0].piso}
                </Typography>
                
              )}
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Procedimiento"} />
              <TextField
                autoComplete="off"
                value={values?.diagnostico}
                name="diagnostico"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item container xs={12} sx={{ m: 3 }}>
              <Grid item xs={6}>
                <Typography variant="h6">Estado</Typography>
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction={"row"}
                gap={2}
                alignContent="center"
                justifyContent={"center"}
              >
                <Typography variant="h6">Inactivo</Typography>
                <IOSSwitch
                  checked={values.estado}
                  name="estado"
                  onChange={(e) =>
                    setValues({ ...values, estado: e.target.checked })
                  }
                />
                <Typography variant="h6">Activo</Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ResponseError error={error} />
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="secondary">
            Guardar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Grid>
  );
};

function validateFields(values) {
  let errors = {};
  Object.keys(values).forEach((key) => {
    if (!values[key]) {
      if (key === "aseguradora" || key === "estado" || key === "diagnostico")
        return;
      errors[key] = "Campo requerido";
    }
  });

  // check if nombre is all letters
  if (values.nombre && !Regex.nombres_personas.test(values.nombre)) {
    errors.nombre = "El nombre solo puede contener letras";
  }

  if (
    values.apellidoPaterno &&
    !Regex.nombres_personas.test(values.apellidoPaterno)
  ) {
    errors.apellidoPaterno = "El apellido paterno solo puede contener letras";
  }
  // check if apellidoMaterno is all letters
  if (
    values.apellidoMaterno &&
    !Regex.nombres_personas.test(values.apellidoMaterno)
  ) {
    errors.apellidoMaterno = "El apellido materno solo puede contener letras";
  }

  // check if medicos is empty
  if (values.medicos && values.medicos.length === 0) {
    errors.medicos = "Selecciona al menos un medico";
  }

  return errors;
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
export default EditarPacienteDialog;
