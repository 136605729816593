import { TextField } from "@mui/material";
import React from "react";

const AreaFiltro = ({ values, setValues }) => {
  const [area, setArea] = React.useState("");
  React.useEffect(() => {
    setValues({
      ...values,
      area: {
        value: area,
        filter: (row) =>
          row.area.toLowerCase().includes(area.toLowerCase()),
      },
    });
  }, [area]);
  React.useEffect(() => {
    if (values.reset) {
        setArea("");
    }
  }, [values.reset]);

  return (
    <TextField
      id="outlined-basic"
      label="Área"
      variant="standard"
      fullWidth
      name="area"
      value={area}
      onChange={(e) => setArea(e.target.value)}
    />
  );
};

export default AreaFiltro;