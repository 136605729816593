import { TextField } from "@mui/material";
import React from "react";

const PisoFiltro = ({ values, setValues }) => {
  const [piso, setPiso] = React.useState("");
  React.useEffect(() => {
    setValues({
      ...values,
      piso: {
        value: piso,
        filter: (row) =>
          row.piso.toLowerCase().includes(piso.toLowerCase()),
      },
    });
  }, [piso]);
  React.useEffect(() => {
    if (values.reset) {
        setPiso("");
    }
  }, [values.reset]);

  return (
    <TextField
      id="outlined-basic"
      label="Piso"
      variant="standard"
      fullWidth
      name="piso"
      value={piso}
      onChange={(e) => setPiso(e.target.value)}
    />
  );
};

export default PisoFiltro;