import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Usuarios from "./subPages/configuracion/Usuarios";
import Aseguradoras from "./subPages/configuracion/Aseguradoras";
import Habitaciones from "./subPages/configuracion/Habitaciones";
import { Alert, IconButton, Snackbar, Typography } from "@mui/material";
import { FiX } from "react-icons/fi";
import socketIOClient from "socket.io-client";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 5 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Configuracion() {

  const [value, setValue] = React.useState(0);
  const [ valor, setValor ] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(null);
  const [ errores, setErrores ] = useState(null);
  const usuario = JSON.parse(localStorage.getItem("userData")) || {};

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect( () =>{
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
    });
    socket.on("notificacionErrorCama", (data) => {
      if(data.message.includes("duplicate key")){
        data.message = "El numero de la habitación ya existe, favor de cambiar.";
      }
      setOpenSnack(true);
      setErrores(data);
    });
  }, []);


  useEffect( () =>{    
    if(usuario.user.rol.includes("Hotelería")){ 
      setValue(2);
      setValor(true);
    }
  },[])

  return (
    <>
      <Snackbar
        autoHideDuration={7000}
        onClose={() => setOpenSnack(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openSnack}
      >
        <Alert
          onClose={() => setOpenSnack(false)}
          open={openSnack}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnack(false)}
            >
              <FiX fontSize="small" />
            </IconButton>
          }
          icon={false}
          sx={{ backgroundColor: "#63666A", color: "white" }}
        >
          <Typography variant={"p"}>
            {" "}
            { errores?.message ? errores?.message : "N/A" }
          </Typography>
        </Alert>
      </Snackbar>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
          >
            <Tab label="Usuarios" {...a11yProps(0)} disabled={ valor } />
            <Tab label="Aseguradoras" {...a11yProps(1)} disabled={ valor } /> 
            <Tab label="Habitaciónes" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Usuarios />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Aseguradoras />
        </TabPanel>
        <TabPanel value={value} index={2}>
            <Habitaciones />
        </TabPanel>
      </Box>
    </>
    
  );
}
