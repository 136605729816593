import {
  Button,
  ButtonGroup,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { FiEdit, FiEye, FiMapPin } from "react-icons/fi";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import {
  MdCheckCircleOutline,
  MdPauseCircleOutline,
  MdPlayCircleOutline,
} from "react-icons/md";
import { GrUserAdmin } from "react-icons/gr";
import { LiaNotesMedicalSolid } from "react-icons/lia";

import { TbBed } from "react-icons/tb";
import { BsDiagram2 } from "react-icons/bs";
import EditarPacienteDialog from "../EditarDialogs/EditarPacienteDialog";
import TRACKER from "../../constants/trackerV2.json";
import InformacionAdicionalDialog from "../DetallePaciente/InformacionAdicionalDialog";

const url = process.env.REACT_APP_URL_BACK;
const iconStyle = {
  color: "black",
  fontSize: "1.1rem",
};
const FichaPaciente = ({ paciente, refresh, small }) => {
  const userRol = JSON.parse(localStorage.getItem("userData")).user.rol;
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dataPaciente, setDataPaciente] = React.useState({});
  const [camas, setCamas] = useState([]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(`${url}/api/v1/habitaciones/listar`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCamas(data)
      })
      .catch((e) => {
        console.log(e);
        setCamas([]);
      });
      
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };
  const calculateEstatus = (estatus) => {
    let estado = "";
    let icon = <></>;
    let style = {
      width: "3rem",
      height: "3rem",
    };
    if (estatus.estadoProceso === 2) {
      estado = "Completo";
      icon = <MdCheckCircleOutline style={style} />;
      style.color = "#43B02A";
    } else if (estatus.estadoProceso === 1) {
      estado = "En proceso";
      icon = <MdPlayCircleOutline style={style} />;
      style.color = "#0072CE";
    } else {
      estado = "Sin iniciar";
      icon = <MdPauseCircleOutline style={style} />;
      style.color = "#E88D21";
    }
    if (!estatus?.estado) {
      estado = "Desactivado";
      style.color = "#BABABA";
    }
    return { estado, icon };
  };

  React.useEffect(() => {
    setDataPaciente(paciente);
  }, [paciente]);

  return (
    <Box sx={{ width: "100%" }}>
      <ListItem
        sx={{
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.main + "80",
          },
          borderRadius: "10px",
        }}
      >
        <Grid container direction={"row"}>
          <Grid item sx={{ mt: "auto", mb: "auto" }} md={1} xs={2}>
            <Tooltip title={"Ver"}>
              <IconButton href={`/paciente/${paciente._id}`}>
                {calculateEstatus(paciente).icon}
              </IconButton>
            </Tooltip>
            <Typography
              component="p"
              variant="body2"
              color="text.primary"
              sx={{
                fontStyle: "italic",
                display: { xs: "none", md: "flex" },
                textAlign: "center",
              }}
            >
              {!small && calculateEstatus(paciente).estado}
            </Typography>
          </Grid>
          {/* clickable box */}
          <Grid
            item
            container
            xs={10}
            md={11}
            sx={{
              cursor: "pointer",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={handleClick}
          >
            <Grid item sx={{ pl: 3 }} md={5} xs={10}>
              <Typography component="span" variant="p" color="text.primary">
                {`${paciente?.nombre} ${paciente?.apellidoPaterno} ${paciente?.apellidoMaterno} `}
              </Typography>
              {paciente?.additionalData?.vip ? (
                <Typography
                  component={"span"}
                  sx={{
                    fontWeight: "bold",
                    backgroundImage:
                      "linear-gradient(\n\tto right,#cb9b51 22%, \n\t#f6e27a 45%,\n\t#f6e27a 50%,\n\t#cb9b51 55%,\n\t#cb9b51 78%,\n\t#cb9b51 100%\n\t)",
                    color: "transparent",
                    WebkitBackgroundClip: "text",
                  }}
                >
                  (VIP)
                </Typography>
              ) : (
                ""
              )}
              {paciente?.additionalData?.tipoAlta === "Defunción" ? (
                <Typography
                  component={"span"}
                  sx={{
                    fontWeight: "bold",
                    backgroundImage:
                      "linear-gradient(\n\tto right,#b4b6b9 22%, \n\t#b4b6b9 45%,\n\t#e3e4e5 50%,\n\t#b4b6b9 55%,\n\t#b4b6b9 78%,\n\t#b4b6b9 100%\n\t)",
                    color: "transparent",
                    WebkitBackgroundClip: "text",
                  }}
                >
                  (DEF)
                </Typography>
              ) : (
                ""
              )}
              <Tooltip
                title={`${
                  paciente?.aseguradora?.acronimo || "Sin aseguradora"
                }`}
              >
                <Typography
                  component="p"
                  variant="body2"
                  color="text.primary"
                  fontStyle={"italic"}
                >
                  {aseguradoraText(paciente)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid
              item
              container
              justifyContent={"space-around"}
              gap={2}
              sx={{ display: { xs: "none", md: "flex" }, pl: 2 }}
              md={6.5}
              xs={0}
            >
              <GroupOfChips paciente={paciente} small={small} camas={camas}/>
            </Grid>
            <Grid
              item
              md={0.5}
              sx={{ ml: "auto" }}
              xs={1}
              container
              justifyContent={"center"}
              alignContent={"center"}
            >
              {open ? <IoIosArrowDropup /> : <IoIosArrowDropdown />}
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{ maxWidth: "100%" }}
      >
        <Grid
          container
          justifyContent={"space-evenly"}
          gap={1}
          sx={{ mt: 2, mb: 2 }}
        >
          <GroupOfChips
            sx={{ display: { xs: "flex", md: "none" } }}
            paciente={paciente}
            camas={camas}
          />

          {small ? (
            <></>
          ) : camas.filter((e) => {return e.numero === paciente.cama}).length !== 0 ? (
            <Chip
              icon={<FiMapPin style={iconStyle} />}
              label={`${camas.filter((e) => {return e.numero === paciente.cama})[0].area} ${
                camas.filter((e) => {return e.numero === paciente.cama})[0].piso
              }`}
            />
          ) : (
            <Chip
              icon={<FiMapPin style={iconStyle} />}
              label={`Sin registrar`}
            />
          )}
        </Grid>
        <Grid container>
          <ButtonGroup
            variant="outlined"
            color="primary"
            aria-label="contained primary button group"
            sx={{ ml: 2, mr: 2, mb: 2, mt: 2, width: "100%" }}
          >
            <Button
              startIcon={<FiEye />}
              href={`/paciente/${paciente._id}`}
              sx={{ width: "100%", maxWidth: "100%" }}
            >
              Ver
            </Button>
            {(userRol?.includes("Administrador") ||
              userRol?.includes("Admisión")) && (
              <Button
                startIcon={<FiEdit />}
                sx={{ width: "100%", maxWidth: "100%" }}
                onClick={() => setOpenDialog(true)}
              >
                Editar
              </Button>
            )}
            <InformacionAdicionalDialog
              datosPaciente={dataPaciente}
              setDataPaciente={setDataPaciente}
              isOnFicha
              setRefresh={refresh}
            ></InformacionAdicionalDialog>
          </ButtonGroup>
        </Grid>
      </Collapse>
      <Divider />
      {openDialog && (
        <EditarPacienteDialog
          id={paciente._id}
          setOpen={setOpenDialog}
          open={openDialog}
          setRefresh={refresh}
        />
      )}
    </Box>
  );
};

const GroupOfChips = ({ paciente, sx, small, camas }) => {
  return (
    <>
      <Tooltip title={"No. de Cuenta"}>
        <Chip
          sx={sx}
          icon={<GrUserAdmin style={iconStyle} />}
          label={`${paciente.noCuenta}`}
        />
      </Tooltip>
      <Tooltip title={"Habitación"}>
        { camas.filter((e) => {return e.numero === paciente.cama}).length !== 0 ? (<Chip
          sx={sx}
          icon={<TbBed style={iconStyle} />}
          label={`${paciente?.cama}`}
        />) : (<Chip
          sx={sx}
          icon={<TbBed style={iconStyle} />}
          label={`${paciente?.cama} Sin Registrar`}
        />)

        }
        
      </Tooltip>

      <Tooltip title={"Procedimiento"}>
        <Chip
          sx={sx}
          icon={<LiaNotesMedicalSolid style={iconStyle} />}
          label={`${paciente?.diagnostico || "Sin registrar."}`}
        />
      </Tooltip>

      {!small && (
        <Tooltip title={"Actividad actual"}>
          <Chip
            sx={sx}
            icon={<BsDiagram2 style={iconStyle} />}
            label={`${TRACKER[paciente?.etapaActual]?.title}`}
          />
        </Tooltip>
      )}
      {!small ? (
        <></>
      ) : camas.filter((e) => {return e.numero === paciente.cama}).length !== 0 ? (
        <Chip
          icon={<FiMapPin style={iconStyle} />}
          label={`${camas.filter((e) => {return e.numero === paciente.cama})[0].area} ${
            camas.filter((e) => {return e.numero === paciente.cama})[0].piso
          }`}
        />
      ) : (
        <Chip icon={<FiMapPin style={iconStyle} />} label={`Sin registrar`} />
      )}
    </>
  );
};

function aseguradoraText(paciente) {
  const aseguradora = paciente?.aseguradora?.acronimo;
  const size = aseguradora?.length;
  if (aseguradora) {
    if (size > 24) {
      return aseguradora.slice(0, 24) + "...";
    } else {
      return aseguradora;
    }
  } else {
    return "Sin aseguradora";
  }
}
export default FichaPaciente;
